import { apiUrl } from '@/config';
import type { ContactFormDTO } from './types';

const contactFormUrl = apiUrl + '/contactform';

export async function submitContactForm(dto: ContactFormDTO): Promise<void> {
  const response = await fetch(`${contactFormUrl}`, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    credentials: 'include',
    body: JSON.stringify(dto),
  });
  if (!response.ok) throw new Error(await response.text());
}
