<script setup lang="ts">
import type { CardTemplateProps } from '@/api/types';
import { getDocumentRouteFromListRoute } from '@/utilities/routeUtils';
import BlockStatusBar from '@/components/BlockStatusBar.vue';
import { useI18n } from 'vue-i18n';
import TagListRenderer from '@/components/TagListRenderer.vue';

const { t } = useI18n();

const props = defineProps<CardTemplateProps>();

const routeName = props.routeName ?? getDocumentRouteFromListRoute();
</script>

<template>
  <RouterLink
    :to="{ name: routeName, params: { blockId: block.blockId } }"
    :class="[
      'flex flex-col break-words bg-gnist-white text-gnist-black shadow-md',
      'mt-[-1.7rem] w-full items-stretch p-4 first:mt-0',
      'border-l-8',
      `border-gnist-${block.category?.color ?? 'gray'}`,
    ]"
  >
    <div class="flex justify-between gap-4">
      <p v-if="block.category" class="text-box grow italic">
        {{
          `${block.category?.name} ${
            block.ownerOrg?.name &&
            block.category?.name !== t(`supplierPage.supplier`)
              ? ` ${t('blockList.from')} ${block.ownerOrg.name}`
              : ''
          }`
        }}
      </p>
      <TagListRenderer :tags="block.tags" />
    </div>
    <div class="mt-1 flex flex-row">
      <h2 class="text-subtitle">
        {{ block.name }}
      </h2>
    </div>
    <p class="text-box mt-2 grow">
      {{ block.description }}
    </p>
    <div class="mb-[-0.5rem] mt-2 flex flex-row">
      <BlockStatusBar :block="block" />
    </div>
  </RouterLink>
</template>
