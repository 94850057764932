import { apiUrl } from '@/config';
import {
  type AlzSystem,
  type ExtendedAlzSystemDefinitionDTO,
  type AlzSystemDefinitionItem,
  type AlzSystemDefinitionDTO,
} from './types';
import {
  compose,
  composeWithRefresh,
  type ComposableResult,
} from './composable';
import { computed, type Ref } from 'vue';
import { throwErrorPageTrigger } from '@/utilities/throwErrorPageTrigger';
import {
  archTypes,
  companies,
  criticalities,
  informationClasses,
} from './masterdata';
import { useCurrentUser } from './auth';
import { addMonths } from 'date-fns';

const githubUrl = apiUrl + '/github';

export function useMyAlzSystems(
  showAll: boolean,
): ComposableResult<AlzSystemDefinitionItem[]> {
  return compose(getMyAlzSystems(showAll));
}

async function getMyAlzSystems(
  showAll: boolean,
): Promise<AlzSystemDefinitionItem[]> {
  const response = await fetch(
    `${githubUrl}/alz${showAll ? '' : '/my_systems'}`,
    {
      method: 'GET',
      credentials: 'include',
    },
  );
  throwErrorPageTrigger(response);
  if (!response.ok) throw new Error(await response.text());
  return await response.json();
}

export function useAlzSystem(
  systemId: string | undefined,
  isSandbox: boolean,
): ComposableResult<AlzSystem> {
  const composable: ComposableResult<ExtendedAlzSystemDefinitionDTO> = compose(
    !systemId ? null : getAlzVendingFilesForApplication(systemId),
  );
  const user = useCurrentUser();
  const data = computed((): AlzSystem => {
    if (!composable.result.value) {
      return {
        isSandboxSystem: isSandbox,
        name: '',
        owner: user.value
          ? {
              objectId: user.value.issuerUserId,
              displayName:
                user.value.fullName ??
                `${user.value.firstName} ${user.value.lastName}`,
            }
          : undefined,
        workloadName: '',
        endDate: isSandbox ? addMonths(new Date(), 6) : undefined,
        environments: [],
        repos: [],
        customProducts: [],
      };
    }
    for (const env of composable.result.value.environments) {
      env.existingFileIdentifier ??= env.fileIdentifier;
    }
    return {
      ...composable.result.value,
      isSandboxSystem: composable.result.value.systemFileData.sandbox,
      name: composable.result.value.systemFileData.name,
      systemId: composable.result.value.systemFileData.id,
      service: composable.result.value.systemFileData.application_uuid
        ? {
            applicationUUID:
              composable.result.value.systemFileData.application_uuid,
          }
        : undefined,
      company: companies.result.value?.find(
        (c) => c.shortName === composable.result.value?.systemFileData.hf,
      ),
      agreementNumber: composable.result.value.agreementNumber
        ? {
            agreementNumber: composable.result.value.agreementNumber,
          }
        : undefined,
      crit: criticalities.result.value?.find(
        (c) => c.key === composable.result.value?.crit,
      ),
      infoClass: informationClasses.result.value?.find(
        (c) => c.key === composable.result.value?.infoClass,
      ),
      archType: archTypes.result.value?.find(
        (c) => c.key === composable.result.value?.archType,
      ),
      ops: composable.result.value!.systemFileData.operations_model,
      opsCi: composable.result.value.systemFileData.operations_ci_uuid,
      repos: composable.result.value.systemFileData.repositories ?? [],
      customProducts: [], // TODO: actually get data from backend
    };
  });
  return { ...composable, result: data };
}

async function getAlzVendingFilesForApplication(
  systemId: string,
): Promise<ExtendedAlzSystemDefinitionDTO> {
  const response = await fetch(`${githubUrl}/alz/${systemId}`, {
    method: 'GET',
    credentials: 'include',
  });
  throwErrorPageTrigger(response);
  if (!response.ok) throw new Error(await response.text());
  return await response.json();
}

export async function createLzSystem(
  dto: Omit<AlzSystemDefinitionDTO, 'systemId'>,
): Promise<AlzSystemDefinitionDTO> {
  const response = await fetch(`${githubUrl}/alz`, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    credentials: 'include',
    body: JSON.stringify(dto),
  });
  if (!response.ok) throw new Error(await response.text());
  return await response.json();
}
export async function updateLzSystem(
  systemId: string,
  dto: Omit<AlzSystemDefinitionDTO, 'systemId'>,
) {
  const response = await fetch(`${githubUrl}/alz/${systemId}`, {
    method: 'PUT',
    headers: { 'Content-Type': 'application/json' },
    credentials: 'include',
    body: JSON.stringify(dto),
  });
  if (!response.ok) throw new Error(await response.text());
}

export function useTeamGroupsForSystem(
  systemId: Ref<string | undefined>,
): ComposableResult<{ [repo: string]: string }> {
  return composeWithRefresh(
    () => (systemId.value ? getTeamGroupsForSystem(systemId.value) : {}),
    systemId,
  );
}

async function getTeamGroupsForSystem(
  systemId: string,
): Promise<{ [repo: string]: string }> {
  const response = await fetch(`${githubUrl}/gh/${systemId}/teamgroups`, {
    method: 'GET',
    credentials: 'include',
  });
  if (!response.ok) throw new Error(await response.text());
  return await response.json();
}
