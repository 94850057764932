<script setup lang="ts">
import ContentPage from '../components/ContentPage.vue';
import AlzSubNav from '@/components/AlzSubNav.vue';
import ButtonComponent from '@/components/ButtonComponent.vue';
import { ParagraphShimmer } from 'vue3-shimmer';
import TextInput from '@/components/forms/InputText.vue';
import Picker from '@/components/forms/InputPicker.vue';
import Dropdown, {
  toDropdownItems,
} from '@/components/forms/InputDropdown.vue';
import Checkbox from '@/components/forms/InputCheckbox.vue';
import TableComponent from '@/components/TableComponent.vue';
import LoadingSpinner from '@/components/LoadingSpinner.vue';
import { computed, ref, watch, watchEffect } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { useI18n } from 'vue-i18n';
import {
  alzOperationTypes,
  type AgreementReference,
  type ApplicationService,
  type AlzEnvironment,
  type Repo,
  type AlzSystem,
  type FieldLabel,
  type AlzSystemDefinitionDTO,
  alzEnvironmentTypes,
  alzSandboxEnvironmentTypes,
  testDataTypes,
} from '@/api/types';
import { translateStringOrLocale } from '@/i18n';
import { createLzSystem, updateLzSystem, useAlzSystem } from '@/api/github';
import { sleepUntil } from '@/utilities/sleep';
import {
  myAgreementReferences,
  applicationServices,
  archTypes,
  companies,
  criticalities,
  informationClasses,
  useDynamicText,
  cpoAgreementReferences,
} from '@/api/masterdata';
import { deepClone } from '@/utilities/deepClone';
import { throwOnError } from '@/api/composable';
import {
  allFieldSets,
  allRichTextSets,
  allSimpleTextSets,
} from '@/api/field_labels';
import MarkdownRenderer from '@/components/block/MarkdownRenderer.vue';
import AdvancedFieldset from '@/components/AdvancedFieldset.vue';
import { mapValueList } from '@/utilities/mapValueList';
import MaterialIcon from '@/components/MaterialIcon.vue';
import ModalComponent from '@/components/ModalComponent.vue';
import { useValidationTracking } from '@/utilities/useValidationTracking';
import InputDate from '@/components/forms/InputDate.vue';
import { addMonths } from 'date-fns';
import { useStoreAbility, useSubject } from '@/abilities';
import type { IInputLabelProps } from '@/components/forms/InputLabel.vue';
import AlzBatProductEditor from '@/components/AlzBatProductEditor.vue';

const { t } = useI18n();
const { can } = useStoreAbility();

const router = useRouter();
const route = useRoute();
const systemId = route.params.systemId as string | undefined;

const isEdit = !!systemId;

const alzFieldKeys = allFieldSets.alzFieldKeys.fields;
const batFieldKeys = allFieldSets.batFieldKeys.fields;
const alzTextKeys = allRichTextSets.alzRichTextKeys.fields;

const { loaded: textLoaded, result: texts } = useDynamicText(
  [allFieldSets.alzFieldKeys.group, allFieldSets.batFieldKeys.group],
  [allSimpleTextSets.alzTextKeys.group, allSimpleTextSets.batTextKeys.group],
  allRichTextSets.alzRichTextKeys.group,
  router,
);

const richTextLookup = computed(() => {
  const lookup: { [key: string]: string } = {};
  for (const txt of texts.value?.dbRichtext ?? []) {
    lookup[`${txt.group}.${txt.identifier}`] = translateStringOrLocale(
      txt.text,
    ).value;
  }
  return lookup;
});
const dbFieldLookup = computed(() => {
  const lookup: { [key: string]: FieldLabel } = {};
  for (const fld of texts.value?.dbFieldLabels ?? []) {
    lookup[`${fld.group}.${fld.field}`] = fld;
  }
  return lookup;
});

const { result: _landingzone, loaded } = throwOnError(
  useAlzSystem(systemId, !!route.query.sandbox),
);

const system = ref<AlzSystem | null>(null);
watch(
  _landingzone,
  () => {
    system.value = deepClone(_landingzone.value);
    // If loading an existing system
    if (system.value?.isSandboxSystem && system.value?.systemId) {
      sandbox_env.value = system.value.environments[0];
      sandbox_env.value.sandbox_repo_name = system.value.repos?.[0]?.name;
    }
  },
  { immediate: true },
);

const submitting = ref(false);
const submitted = ref(false);
const submitFailed = ref(false);
async function submitOrder(): Promise<void> {
  if (!system.value) {
    throw new Error('No data?!?!');
  }
  if (!isValid.value) {
    throw new Error('Form is not valid!');
  }
  const environments = sandbox_env.value
    ? [sandbox_env.value]
    : system.value.environments;
  for (const env of environments) {
    if (env.existingFileIdentifier) {
      env.fileIdentifier = env.existingFileIdentifier;
    }
  }
  const dto: AlzSystemDefinitionDTO = {
    agreementNumber: system.value.agreementNumber?.agreementNumber,
    costCenter: system.value.agreementNumber?.costCenter,
    endDate: system.value.endDate,
    ownerObjectId: system.value.owner?.objectId,
    crit: system.value.crit?.key,
    infoClass: system.value.infoClass?.key,
    archType: system.value.archType?.key,
    vendingRepo: system.value.archType?.targetRepo,
    ownerTag: system.value.ownerTag,
    workloadName: system.value.workloadName,
    environments,
    systemFileData: {
      name: system.value.name,
      application_uuid: system.value.service?.applicationUUID,
      hf: system.value.company?.shortName,
      operations_model: system.value.ops,
      operations_ci_uuid:
        system.value.ops === 'delegated_ops' ? system.value.opsCi : undefined,
      sandbox: system.value.isSandboxSystem,
      repositories: sandbox_env.value
        ? sandbox_env.value.sandbox_repo_name
          ? [
              {
                name: sandbox_env.value.sandbox_repo_name,
                landing_zones: [sandbox_env.value.fileIdentifier],
              },
            ]
          : []
        : system.value.repos,
    },
    productsData: {
      customProducts: system.value.customProducts,
      orderRoles: Object.fromEntries(
        system.value?.environments.map((env) => [
          env.fileIdentifier,
          env.orderRoles ?? [],
        ]),
      ),
    },
  };
  submitting.value = true;
  submitted.value = false;
  submitFailed.value = false;
  try {
    if (!system.value.systemId) {
      const result = await createLzSystem(dto);
      system.value.systemId = result.systemFileData.id;
      router.push({ path: '/orders/alz' });
    } else {
      await updateLzSystem(system.value.systemId, dto);
    }
    submitted.value = true;
  } catch (err) {
    // TODO: better error handling
    console.error(err);
    submitFailed.value = true;
  }
  submitting.value = false;
}

const companyOptions = computed(() =>
  toDropdownItems(
    companies.result.value,
    (c) => [
      c.shortName.toString(),
      translateStringOrLocale(c.name ?? '').value,
      c,
    ],
    true,
  ),
);
const critOptions = computed(() =>
  toDropdownItems(
    criticalities.result.value,
    (c) => [c.key.toString(), translateStringOrLocale(c.text).value, c],
    true,
  ),
);
const infoClassOptions = computed(() =>
  toDropdownItems(
    informationClasses.result.value?.filter(
      (infoClass) => !system.value?.isSandboxSystem || infoClass.sandbox,
    ),
    (c) => [c.key, translateStringOrLocale(c.text).value, c],
    true,
  ),
);
const archtypeOptions = computed(() =>
  toDropdownItems(
    archTypes.result.value?.filter((archType) => {
      return (
        (archType.roles?.length ?? 0) == 0 ||
        can('use', useSubject('AlzArchetype', archType))
      );
    }),
    (c) => [c.key, translateStringOrLocale(c.text).value, c],
    true,
  ),
);

const operationOptions = mapValueList(
  alzOperationTypes,
  'orders.alz.fields.ops',
  true,
);
const envTypeOptions = computed(() =>
  mapValueList(
    system.value?.isSandboxSystem
      ? alzSandboxEnvironmentTypes
      : alzEnvironmentTypes,
    'orders.alz.fields.envColumns.type',
    true,
  ),
);
const testDataOptions = computed(() =>
  mapValueList(testDataTypes, 'orders.alz.fields.envColumns.test_data', true),
);

const environmentList = computed(() =>
  toDropdownItems(system.value?.environments ?? [], (c) => [
    c.fileIdentifier,
    c.name,
  ]),
);
const repoList = computed(() =>
  toDropdownItems(system.value?.repos ?? [], (c) => [c.name, c.name]),
);

function addEnvironment() {
  if (!system.value) return null;
  const emptyEnv: AlzEnvironment = {
    fileIdentifier: '',
    name: '',
    sandbox: true,
  };
  system.value.environments = [...system.value.environments, emptyEnv];
  return emptyEnv;
}
function addRepo() {
  if (!system.value) return null;
  const emptyRepo: Repo = {
    name: '',
    landing_zones: [],
  };
  system.value.repos = [...system.value.repos, emptyRepo];
  return emptyRepo;
}

async function searchServices(query: string): Promise<ApplicationService[]> {
  if (!applicationServices.result.value) return [];
  const itemLimit = 10;
  const matches =
    query.trim() == ''
      ? applicationServices.result.value
      : applicationServices.result.value.filter((service) =>
          service.name?.toLowerCase().includes(query),
        );
  return matches.slice(0, itemLimit);
}
async function getServiceById(id: string | number) {
  await sleepUntil(() => applicationServices.loaded.value, 1000, 10);
  return (
    applicationServices.result.value?.find((a) => a.applicationUUID == id) ?? {
      applicationUUID: id.toString(),
      name: t('orders.alz.unknownService'),
    }
  );
}
const useCPOagreementList = can('use', 'AgreementReference');
const agreementReferences = computed(() =>
  useCPOagreementList
    ? cpoAgreementReferences.result.value
    : myAgreementReferences.result.value,
);
const missingAgreementReference = computed(
  () =>
    (agreementReferences.value?.length ?? 0) === 0 &&
    !system.value?.agreementNumber?.agreementNumber,
);
async function searchAgreements(query: string): Promise<AgreementReference[]> {
  if (!agreementReferences.value) return [];
  const itemLimit = 10;
  const matches =
    query.trim() == ''
      ? agreementReferences.value
      : agreementReferences.value.filter((agreement) =>
          agreement.agreementNumber.toLowerCase().includes(query),
        );
  for (const match of matches) {
    match.readFromDb = true;
  }
  return matches.slice(0, itemLimit);
}
async function getAgreementByNumber(num: string | number) {
  await sleepUntil(
    () =>
      useCPOagreementList
        ? cpoAgreementReferences.loaded.value
        : myAgreementReferences.loaded.value,
    1000,
    10,
  );
  const match = agreementReferences.value?.find(
    (a) => a.agreementNumber == num,
  );
  if (match) match.readFromDb = true;
  return match;
}

//#region Validation logics
// Production workloads cannot be sandboxes
watchEffect(() => {
  for (const env of system.value?.environments ?? []) {
    if (env.type == 'Prod') {
      env.sandbox = false;
    }
  }
});
// Set default values for sandboxes
const sandbox_env = ref<AlzEnvironment>();
watchEffect(() => {
  if (system.value?.isSandboxSystem) {
    sandbox_env.value ??= {
      existingFileIdentifier:
        system.value.environments[0]?.existingFileIdentifier,
      fileIdentifier: system.value.environments[0]?.fileIdentifier ?? '',
      name: 'sbox',
      sandbox: true,
    };
  } else {
    sandbox_env.value = undefined;
  }
});
// Calculate file names for landing zones
function calculateFileIdentifier(lz: AlzEnvironment) {
  return (
    (system.value?.name + (lz?.name ?? ''))
      .toLowerCase()
      .replace(/[^a-z0-9]/g, '') + '.json'
  );
}
watchEffect(() => {
  for (const lz of system.value?.environments ?? []) {
    lz.fileIdentifier = calculateFileIdentifier(lz);
  }
  if (sandbox_env.value) {
    sandbox_env.value.fileIdentifier = calculateFileIdentifier(
      sandbox_env.value,
    );
  }
});
//#endregion
const showValidationMessages = ref<boolean>(false);
const { formRef, isValid } = useValidationTracking(
  [system, sandbox_env],
  showValidationMessages,
  true,
  isEdit,
);
function getDynamicLabelProps<
  TGroup extends keyof typeof allFieldSets,
  TKey extends keyof (typeof allFieldSets)[TGroup]['fields'],
>(
  group: TGroup,
  key: TKey,
): Pick<IInputLabelProps, 'dynamicTextKey' | 'mdDescription' | 'link'> {
  if (group === 'alzFieldKeys') {
    const textKey = alzFieldKeys[key as keyof typeof alzFieldKeys];
    return {
      dynamicTextKey: textKey,
      mdDescription: dbFieldLookup.value[textKey]?.richTextDescription,
      link: dbFieldLookup.value[textKey]?.link,
    };
  } else if (group === 'batFieldKeys') {
    const textKey = batFieldKeys[key as keyof typeof batFieldKeys];
    return {
      dynamicTextKey: textKey,
      mdDescription: dbFieldLookup.value[textKey]?.richTextDescription,
      link: dbFieldLookup.value[textKey]?.link,
    };
  } else {
    throw new Error(`Unknown set of fields: ${group}`);
  }
}
const showBatModal = ref(false);
</script>

<template>
  <ContentPage
    :title="t('header.items.orders.alz_order_form')"
    class="bg-gnist-gray-light-light"
  >
    <ParagraphShimmer
      v-if="!loaded || !textLoaded"
      :lines="6"
      :random-size="true"
      class="mx-auto w-full max-w-5xl"
    />
    <div v-else-if="!system">(noe gikk galt)</div>
    <div v-else class="mx-2 py-8 md:mx-auto">
      <AlzSubNav />
      <!-- BAT Products ordering is not finished, so for now we hide the button to open it up -->
      <div v-show="false" class="flex justify-end">
        <ButtonComponent
          text="orders.bat.buttons.values.showModal"
          class=""
          @click="showBatModal = true"
        />

        <ModalComponent
          :show-modal="showBatModal"
          title="orders.bat.title"
          size="big"
          primary-button-type="none"
          @close="showBatModal = false"
        >
          <AlzBatProductEditor
            v-model="system"
            :get-dynamic-label-props="getDynamicLabelProps"
            :environment-list="environmentList"
            :repo-list="repoList"
          />
        </ModalComponent>
      </div>
      <AdvancedFieldset
        v-if="isEdit"
        class="bg-gnist-gray-light p-4"
        v-bind="getDynamicLabelProps('alzFieldKeys', 'status')"
      >
        <template v-if="system.pullRequestUrl">
          Venter på behandling av
          <a class="underline" :href="system.pullRequestUrl">
            PR#{{ system.pullRequestId }}
          </a>
        </template>
        <template v-else-if="system.systemFileUrl">
          Siste endringer levert.<br />
          Konfigurasjon lagret her:
          <a class="underline" :href="system.systemFileUrl">
            {{ system.systemFileUrl }}
          </a>
        </template>
      </AdvancedFieldset>
      <form ref="formRef" class="flex flex-col gap-12">
        <AdvancedFieldset
          class="flex min-w-0 grid-cols-2 flex-col gap-12 border bg-gnist-gray-light p-4 ps-4 md:grid"
          v-bind="getDynamicLabelProps('alzFieldKeys', 'metadata')"
        >
          <div class="grid justify-items-center">
            <MarkdownRenderer
              :value="
                t(
                  `${system.isSandboxSystem ? alzFieldKeys.sandboxdetails : alzFieldKeys.metadata}.description`,
                  '&nbsp;',
                )
              "
              blob-location="public"
            />
          </div>
          <div class="row-start-1">
            <Dropdown
              v-model="system.company"
              v-bind="getDynamicLabelProps('alzFieldKeys', 'company')"
              :options="companyOptions"
              :getkey="(t) => t?.shortName"
              required
            />
            <Picker
              v-if="!system.isSandboxSystem"
              v-model="system.service"
              v-bind="getDynamicLabelProps('alzFieldKeys', 'service')"
              suggestedlabel="orders.alz.searchMatchesLabel"
              :getkey="(val) => val?.applicationUUID ?? ''"
              :gettext="(val) => val?.name ?? ''"
              :taglistclass="['w-full grow [&>*]:w-full [&>*]:justify-between']"
              :filter="searchServices"
              :get-by-id="getServiceById"
              :max-items="1"
              show-empty-suggestions
              required
            />
            <div v-if="missingAgreementReference" class="alert alert-warning">
              <MarkdownRenderer
                :value="
                  richTextLookup[alzTextKeys.agreementNumberMissing] ??
                  'Ta kontakt for å få registrert ditt avtalenummer før bestilling'
                "
                blob-location="public"
              />
            </div>
            <template v-else>
              <Picker
                v-model="system.agreementNumber"
                v-bind="getDynamicLabelProps('alzFieldKeys', 'agreement')"
                suggestedlabel="orders.alz.searchMatchesLabel"
                :getkey="(val) => val?.agreementNumber ?? ''"
                :gettext="
                  (val) =>
                    val?.readFromDb
                      ? `${val.agreementNumber}, ${t(`${alzFieldKeys.costCenter}.label`)} ${val.costCenter ?? '-'}${val.label ? `, ${val.label}` : ''}`
                      : ''
                "
                :taglistclass="[
                  'w-full grow [&>*]:w-full [&>*]:justify-between',
                ]"
                :filter="searchAgreements"
                :get-by-id="getAgreementByNumber"
                :max-items="1"
                show-empty-suggestions
                required
              />
            </template>
            <TextInput
              v-model="system.name"
              v-bind="
                getDynamicLabelProps(
                  'alzFieldKeys',
                  system.isSandboxSystem ? 'sandboxName' : 'lzName',
                )
              "
              required
              :minlength="1"
              :maxlength="80"
            />
            <InputDate
              v-if="system.isSandboxSystem"
              v-model="system.endDate"
              v-bind="getDynamicLabelProps('alzFieldKeys', 'endDate')"
              required
              :max="addMonths(new Date(), 6)"
              :disabled="isEdit"
            />
            <TextInput
              v-if="!isEdit"
              :model-value="system.owner?.displayName"
              v-bind="getDynamicLabelProps('alzFieldKeys', 'owner')"
              required
              :minlength="1"
              :maxlength="20"
              disabled
            />
          </div>
        </AdvancedFieldset>

        <AdvancedFieldset
          class="flex min-w-0 grid-cols-2 flex-col gap-12 bg-gnist-gray-light md:grid"
          v-bind="getDynamicLabelProps('alzFieldKeys', 'type')"
        >
          <div class="grid justify-items-center">
            <MarkdownRenderer
              :value="t(`${alzFieldKeys.type}.description`, '&nbsp;')"
              blob-location="public"
            />
          </div>

          <div class="row-start-1">
            <Dropdown
              v-model="system.crit"
              v-bind="getDynamicLabelProps('alzFieldKeys', 'crit')"
              :options="critOptions"
              :getkey="(t) => t?.key"
              required
            />
            <Dropdown
              v-model="system.infoClass"
              v-bind="getDynamicLabelProps('alzFieldKeys', 'infoClass')"
              :options="infoClassOptions"
              :getkey="(t) => t?.key"
              required
            />
            <Dropdown
              v-if="sandbox_env"
              v-model="sandbox_env.type"
              v-bind="getDynamicLabelProps('alzFieldKeys', 'envColumns.type')"
              :options="envTypeOptions"
              :getkey="(t) => t"
              required
            />
            <Dropdown
              v-if="sandbox_env"
              v-model="sandbox_env.test_data"
              v-bind="
                getDynamicLabelProps('alzFieldKeys', 'envColumns.test_data')
              "
              :options="testDataOptions"
              :getkey="(t) => t"
              required
            />
            <Dropdown
              v-model="system.archType"
              v-bind="getDynamicLabelProps('alzFieldKeys', 'archType')"
              :options="archtypeOptions"
              :getkey="(t) => t?.key"
              required
            />
            <TextInput
              v-model="system.workloadName"
              v-bind="getDynamicLabelProps('alzFieldKeys', 'workloadName')"
              :validate="/^[a-zA-Z0-9]{2,8}$/"
              :validation-label="t('orders.alz.wlNameValidation')"
              required
              :minlength="2"
              :maxlength="8"
            />
            <TextInput
              v-if="system.isSandboxSystem"
              v-model="system.ownerTag"
              v-bind="getDynamicLabelProps('alzFieldKeys', 'ownerTag')"
              required
            />
            <TextInput
              v-if="system.isSandboxSystem && sandbox_env"
              v-model="sandbox_env.sandbox_repo_name"
              v-bind="getDynamicLabelProps('alzFieldKeys', 'sandboxreponame')"
              :validate="/^[a-z0-9]+(-[a-z0-9]+)*$/"
              :validation-label="t('orders.alz.repoNameValidation')"
              :minlength="1"
              :maxlength="50"
            />
          </div>
        </AdvancedFieldset>

        <AdvancedFieldset
          v-if="!system.isSandboxSystem"
          class="col-span-2 bg-gnist-gray-light"
          v-bind="getDynamicLabelProps('alzFieldKeys', 'env')"
        >
          <TableComponent
            size="table-md"
            :rows="system.environments"
            show-spinner
            i18n-key="orders.alz.fields.envColumns"
            uses-dynamic-text-keys
            :get-key="(_, idx) => idx"
            :columns="['name', 'type', 'test_data', 'sandbox']"
            :create="{
              idProperty: 'name',
              onCreateClick: addEnvironment,
              title: 'orders.alz.buttons.values.addEnv',
            }"
            :delete="{
              getIdentificator: (val) => val.name,
              onDelete: (row) => {
                if (!system?.environments) return;
                system.environments = system.environments.filter(
                  (e) => e.name !== row.name,
                );
              },
            }"
            class="EnvTable [&_td_label]:border-l [&_td_label]:pl-4"
          >
            <template #columnHeader="{ item: env }">
              <TextInput
                v-model="env.name"
                v-bind="getDynamicLabelProps('alzFieldKeys', 'envColumns.name')"
                required
                :minlength="2"
                :maxlength="4"
                sr-only
                class="[&_input]:max-w-20"
              />
            </template>
            <template #columns="{ item: env }">
              <td>
                <Dropdown
                  v-model="env.type"
                  v-bind="
                    getDynamicLabelProps('alzFieldKeys', 'envColumns.type')
                  "
                  :options="envTypeOptions"
                  :getkey="(t) => t"
                  required
                  sr-only
                  class="min-w-32"
                />
              </td>
              <td>
                <Dropdown
                  v-if="env.type !== 'Prod'"
                  v-model="env.test_data"
                  v-bind="
                    getDynamicLabelProps('alzFieldKeys', 'envColumns.test_data')
                  "
                  :options="testDataOptions"
                  :getkey="(t) => t"
                  required
                  sr-only
                  class="min-w-32"
                />
              </td>
              <td>
                <Checkbox
                  v-model="env.sandbox"
                  v-bind="
                    getDynamicLabelProps('alzFieldKeys', 'envColumns.sandbox')
                  "
                  direction="vertical"
                  mode="toggle"
                  sr-only
                  :disabled="env.type === 'Prod'"
                />
              </td>
            </template>
          </TableComponent>
        </AdvancedFieldset>
        <AdvancedFieldset
          v-if="!system.isSandboxSystem"
          class="col-span-2 bg-gnist-gray-light"
          v-bind="getDynamicLabelProps('alzFieldKeys', 'repos')"
        >
          <TableComponent
            size="table-md"
            :rows="system.repos"
            show-spinner
            i18n-key="orders.alz.fields.repoColumns"
            uses-dynamic-text-keys
            :get-key="(_, idx) => idx"
            :columns="['name', 'landing_zones']"
            :create="{
              idProperty: 'name',
              onCreateClick: addRepo,
              title: 'orders.alz.buttons.values.addRepo',
            }"
            :delete="{
              getIdentificator: (val) => val.name,
              onDelete: (row) => {
                if (!system?.repos) return;
                system.repos = system.repos.filter((r) => r.name !== row.name);
              },
            }"
          >
            <template #columnHeader="{ item: repo }">
              <TextInput
                v-model="repo.name"
                v-bind="
                  getDynamicLabelProps('alzFieldKeys', 'repoColumns.name')
                "
                :validate="/^[a-z0-9]+(-[a-z0-9]+)*$/"
                :validation-label="t('orders.alz.repoNameValidation')"
                required
                :minlength="1"
                :maxlength="50"
                sr-only
                class="!flex-row-reverse items-center gap-2"
              />
            </template>
            <template #columns="{ item: repo }">
              <td>
                <Dropdown
                  v-model="repo.landing_zones"
                  v-bind="
                    getDynamicLabelProps(
                      'alzFieldKeys',
                      'repoColumns.landing_zones',
                    )
                  "
                  :options="environmentList"
                  :getkey="(t) => t"
                  :multiple="{ emptyLabel: '' }"
                  required
                  sr-only
                />
              </td>
            </template>
          </TableComponent>
        </AdvancedFieldset>

        <AdvancedFieldset
          v-if="!system.isSandboxSystem"
          class="flex min-w-0 grid-cols-2 flex-col gap-12 border bg-gnist-gray-light p-4 ps-4 md:grid"
          v-bind="getDynamicLabelProps('alzFieldKeys', 'ops')"
        >
          <div class="grid justify-items-center">
            <MarkdownRenderer
              :value="t(`${alzFieldKeys.ops}.description`, '&nbsp;')"
              blob-location="public"
            />
          </div>

          <div class="row-start-1">
            <TextInput
              v-model="system.ownerTag"
              v-bind="getDynamicLabelProps('alzFieldKeys', 'ownerTag')"
              required
            />
            <Dropdown
              v-model="system.ops"
              v-bind="getDynamicLabelProps('alzFieldKeys', 'ops')"
              :options="operationOptions"
              :getkey="(t) => t"
              required
            />
            <TextInput
              v-if="system.ops === 'delegated_ops'"
              v-model="system.opsCi"
              v-bind="getDynamicLabelProps('alzFieldKeys', 'opsCi')"
              required
              :minlength="1"
              :maxlength="20"
            />
          </div>
        </AdvancedFieldset>
        <div class="flex items-center justify-end gap-4">
          <MaterialIcon
            v-if="submitted"
            icon="check_circle"
            label="orders.alz.buttons.values.submitted"
            class="material-symbols-rounded ml-1 cursor-default text-3xl font-extrabold text-gnist-green"
          />
          <MaterialIcon
            v-if="submitFailed"
            icon="error_outline"
            label="orders.alz.buttons.values.submitted"
            class="material-symbols-rounded ml-1 cursor-default text-3xl font-extrabold text-gnist-red"
          />
          <LoadingSpinner v-if="submitting" class="h-9 w-9" />
          <ButtonComponent
            :text="
              !isValid
                ? 'orders.alz.buttons.values.validate'
                : isEdit
                  ? 'orders.alz.buttons.values.update'
                  : 'orders.alz.buttons.values.submit'
            "
            class="my-2 w-auto self-end justify-self-start"
            :disabled="submitting"
            @click.prevent="
              isValid ? submitOrder() : (showValidationMessages = true)
            "
          />
        </div>
      </form>
    </div>
  </ContentPage>
</template>

<style>
.EnvTable th,
.EnvTable td {
  @apply align-top;
}
.EnvTable label {
  @apply grid;
  grid-template-areas:
    'input info'
    'desc  desc';
  @apply items-center;
}
.EnvTable label .GnistInputLabel .LabelEnd {
  @apply order-first;
}
.EnvTable label .GnistInputLabel {
  @apply w-max;
  grid-area: info;
}
.EnvTable label input {
  grid-area: input;
  @apply mr-1;
}
.EnvTable label .LabelDescription {
  grid-area: desc;
}
.EnvTable .table-md :where(th, td) {
  @apply pr-0;
}
.EnvTable .ButtonRow {
  @apply pr-4;
  @apply align-middle;
  @apply text-right;
  /* Grow the last column to the remaining space */
  @apply w-full;
}
</style>
