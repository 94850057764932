type FieldSetDetailsInternal<
  TFields extends readonly string[] = readonly string[],
  TGroup extends string = string,
> = {
  group: TGroup;
  richtext?: boolean;
  fields: {
    [TKey in TFields[number]]: `${TGroup}.${TKey}`;
  };
};
export type FieldSetDetails<
  TFields extends readonly string[] = readonly string[],
> = FieldSetDetailsInternal<TFields, FieldSetKey>;
export type TextSetDetails<
  TFields extends readonly string[] = readonly string[],
> = FieldSetDetailsInternal<TFields, TextSetKey>;

function getFieldSetKeys<
  TFields extends readonly string[],
  TGroup extends string = string,
>(group: TGroup, fields: TFields, richtext?: boolean) {
  return {
    group,
    richtext,
    fields: Object.fromEntries(
      fields.map((f) => [f, `${group}.${f}`]),
    ) as FieldSetDetailsInternal<TFields, TGroup>['fields'],
  } satisfies FieldSetDetailsInternal<TFields, TGroup>;
}

export type FieldSetKey =
  (typeof allFieldSets)[keyof typeof allFieldSets]['group'];
export function getFieldSetByKey(key: FieldSetKey): FieldSetDetails {
  if (!key) return { group: '' as FieldSetKey, fields: {} };
  const match = Object.values(allFieldSets).find((f) => f.group === key);
  if (!match) throw new Error('This should not happen');
  return match;
}
export type TextSetKey =
  | (typeof allSimpleTextSets)[keyof typeof allSimpleTextSets]['group']
  | (typeof allRichTextSets)[keyof typeof allRichTextSets]['group'];
export function getTextSetByKey(key: TextSetKey): TextSetDetails {
  if (!key) return { group: '' as TextSetKey, fields: {} };
  const simpleSet = Object.values(allSimpleTextSets).find(
    (f) => f.group === key,
  );
  const richSet = Object.values(allRichTextSets).find((f) => f.group === key);
  if (!simpleSet && !richSet) throw new Error('This should not happen');
  return Object.assign(simpleSet ?? {}, richSet ?? {}) as TextSetDetails;
}

const alzFieldKeys = getFieldSetKeys('orders.alz.fields', [
  'company',
  'service',
  'agreement',
  'costCenter',
  'lzName',
  'sandboxName',
  'endDate',
  'owner',
  'crit',
  'infoClass',
  'archType',
  'envColumns.name',
  'envColumns.sandbox',
  'envColumns.type',
  'envColumns.test_data',
  'repoColumns.name',
  'repoColumns.landing_zones',
  'ownerTag',
  'workloadName',
  'ops',
  'opsCi',
  'status',
  'metadata',
  'sandboxdetails',
  'sandboxreponame',
  'type',
  'env',
  'repos',
  'ops',
] as const);

const batFieldKeys = getFieldSetKeys('orders.bat.fields', [
  'roleColumns.name',
  'roleColumns.canOrder',
  'customProduct',
  'productColumns.type',
  'productColumns.objectId',
  'productColumns.roleId',
] as const);

const kssContactFieldKeys = getFieldSetKeys('contact.kss.fields', [
  'topic',
  'senderEmail',
  'senderName',
  'title',
  'request',
] as const);

export const allFieldSets = {
  alzFieldKeys,
  batFieldKeys,
  kssContactFieldKeys,
} as const satisfies {
  [key: string]: FieldSetDetailsInternal;
};

const alzTextKeys = getFieldSetKeys('orders.alz', [
  'buttons.values.addEnv',
  'buttons.values.addRepo',
  'buttons.values.update',
  'buttons.values.submit',
  'buttons.values.submitted',
  'unknownService',
  'searchMatchesLabel',
  'fields.ops.values.team_ops',
  'fields.ops.values.cpo_ops',
  'fields.ops.values.delegated_ops',
  'fields.envColumns.type.values.Prod',
  'fields.envColumns.type.values.PreProd',
  'fields.envColumns.type.values.Test',
  'fields.envColumns.type.values.Development',
  'fields.envColumns.type.values.Troubleshooting',
  'fields.envColumns.type.values.Trial',
  'fields.envColumns.type.values.Course',
  'fields.envColumns.test_data.values.synthetic',
  'fields.envColumns.test_data.values.anonymized',
  'fields.envColumns.test_data.values.indirect_identifiable',
  'fields.envColumns.test_data.values.person_sensitive',
] as const);

const batTextKeys = getFieldSetKeys('orders.bat', [
  'buttons.values.showModal',
  'buttons.values.addProduct',
  'buttons.values.deleteProduct',
  'fields.productColumns.type.values.lz_role',
  'fields.productColumns.type.values.gh_role',
] as const);

const kssContactTextKeys = getFieldSetKeys('contact.kss', [
  'buttons.values.send',
] as const);

export const allSimpleTextSets = {
  alzTextKeys,
  batTextKeys,
  kssContactTextKeys,
} satisfies {
  [key: string]: FieldSetDetailsInternal;
};

export const richTextPrefix = 'richtext.';
const alzRichTextKeys = getFieldSetKeys(
  `${richTextPrefix}orders.alz`,
  ['agreementNumberMissing'] as const,
  true,
);

export const allRichTextSets = { alzRichTextKeys } satisfies {
  [key: string]: FieldSetDetailsInternal;
};

export const allTextSets = Object.assign(allSimpleTextSets, allRichTextSets);
