<script lang="ts" setup>
import { type PageTemplateProps } from '@/api/types';
import BlockPageBanner from '@/components/block/BlockPageBanner.vue';
import BlockRenderer from '@/components/block/BlockRenderer.vue';
import { ref } from 'vue';
import { useI18n } from 'vue-i18n';
import TagListGroup from '@/components/TagListGroup.vue';
import { useBlocksForOrganization } from '@/api/organization';
import { throwOnError } from '@/api/composable';
import { translateStringOrLocale } from '@/i18n';
import { getDocumentRouteFromListRoute } from '@/utilities/routeUtils';

const { t } = useI18n();

const props = defineProps<PageTemplateProps>();
const containers = ref<{ idx: number; el: HTMLElement }[]>([]);

const routeName = getDocumentRouteFromListRoute();
const orgId = props.block.ownerOrg?.organizationId ?? '';
const { result: orgBlocks } = throwOnError(useBlocksForOrganization(orgId));
</script>

<template>
  <div class="px-4 py-12 sm:px-6 lg:px-8" :class="[`bg-gnist-${bgColor}`]">
    <div class="max-w-screen-md sm:max-w-2xl md:max-w-4xl lg:max-w-5xl">
      <BlockPageBanner
        :title="block.name ?? ''"
        :block="block"
        :tags="block.tags ?? []"
        :class="[`bg-gnist-${bgColor}`]"
        :is-preview="!!isPreview"
        :no-left-margin="true"
        :right-margin="false"
      >
        <template #description>
          <RouterLink
            :to="{
              name: routeName,
              params: {
                blockId: orgBlocks?.blocks.find(
                  (b) =>
                    translateStringOrLocale(b.category.name).value ===
                    t('supplierPage.supplier'),
                )?.blockId,
              },
            }"
          >
            <div class="flex gap-1">
              <p>{{ t('productPage.suppliedBy') }}</p>
              <p class="underline">
                {{ block.ownerOrg?.name }}
              </p>
            </div>
          </RouterLink>
        </template>
      </BlockPageBanner>

      <TagListGroup
        v-if="block.category"
        class="ml-6"
        :category-name="translateStringOrLocale(block.category.name).value"
        :tags="block.tags"
      />

      <div
        class="mx-4 mt-6 md:mt-12"
        data-cy-id="contentContainer"
        :data-cy-state="!!block.content ? 'loaded' : 'not_loaded'"
      >
        <BlockRenderer
          v-model:containers="containers"
          :block="block"
          class="mt-8"
        />
      </div>
    </div>
  </div>
</template>
