import type {
  CardTemplateDefinition,
  PageTemplateDefinition,
} from './api/types';
import ApiPage from './components/block/templates/ApiPage.vue';
import BlockCard from './components/block/templates/BlockCard.vue';
import BlockPage from './components/block/templates/BlockPage.vue';
import FullPage from './components/block/templates/FullPage.vue';
import ProductCard from './components/block/templates/ProductCard.vue';
import ProductPage from './components/block/templates/ProductPage.vue';
import SearchCard from './components/block/templates/SearchCard.vue';
import SupplierPage from './components/block/templates/SupplierPage.vue';

const _cardTemplates = [
  {
    type: 'card',
    id: 'blockCard',
    component: BlockCard,
    isLink: true,
    requiredFields: [],
  },
  {
    type: 'card',
    id: 'productCard',
    component: ProductCard,
    isLink: true,
    requiredFields: [{ name: 'productimage', type: 'image' }],
  },
  {
    type: 'card',
    id: 'searchCard',
    component: SearchCard,
    isLink: false,
    requiredFields: [],
  },
] as const satisfies CardTemplateDefinition[];
// This extra layer is needed to make sure option properties are still present in the type
export const cardTemplates: ((typeof _cardTemplates)[number] &
  CardTemplateDefinition)[] = _cardTemplates;
export type cardTemplateIds = (typeof _cardTemplates)[number]['id'];

const _pageTemplates = [
  {
    type: 'page',
    id: 'blockPage',
    component: BlockPage,
    hasVersionSelector: true,
    bgColor: 'white',
    requiredFields: [{ name: 'content', type: 'markdown' }],
  },
  {
    type: 'page',
    id: 'apiPage',
    component: ApiPage,
    hasVersionSelector: true,
    bgColor: 'white',
    requiredFields: [
      { name: 'content', type: 'markdown', canUnsetRequired: true },
      {
        name: 'apim',
        type: 'apim',
        defaultTitle: 'API-operasjoner',
        canUnsetRequired: true,
      },
    ],
  },
  {
    type: 'page',
    id: 'productPage',
    component: ProductPage,
    hasVersionSelector: false,
    bgColor: 'white',
    requiredFields: [{ name: 'productimage', type: 'image' }],
  },
  {
    type: 'page',
    id: 'supplierPage',
    component: SupplierPage,
    hasVersionSelector: false,
    bgColor: 'white',
    requiredFields: [{ name: 'content', type: 'markdown' }],
  },
  {
    type: 'page',
    id: 'fullPage',
    component: FullPage,
    hasVersionSelector: false,
    bgColor: 'blue-light-light',
    fgColor: 'black',
    requiredFields: [{ name: 'content', type: 'markdown' }],
  },
  {
    type: 'page',
    id: 'fullPage_white',
    component: FullPage,
    hasVersionSelector: false,
    bgColor: 'white',
    fgColor: 'black',
    requiredFields: [],
  },
  {
    type: 'page',
    id: 'fullPage_discussion',
    component: FullPage,
    hasVersionSelector: false,
    showDiscussion: true,
    bgColor: 'blue-light-light',
    requiredFields: [{ name: 'content', type: 'markdown' }],
  },
] as const satisfies PageTemplateDefinition[];
// This extra layer is needed to make sure option properties are still present in the type
export const pageTemplates: ((typeof _pageTemplates)[number] &
  PageTemplateDefinition)[] = _pageTemplates;
export type pageTemplateIds = (typeof _pageTemplates)[number]['id'];
