<script setup lang="ts" generic="T = string">
import ContentPage from '../components/ContentPage.vue';
import { ParagraphShimmer } from 'vue3-shimmer';
import LoadingSpinner from '@/components/LoadingSpinner.vue';
import ButtonComponent from '@/components/ButtonComponent.vue';
import TextInput from '@/components/forms/InputText.vue';
import Dropdown, {
  toDropdownItems,
} from '@/components/forms/InputDropdown.vue';
import MaterialIcon from '@/components/MaterialIcon.vue';
import { useI18n } from 'vue-i18n';
import type { IInputLabelProps } from '@/components/forms/InputLabel.vue';
import { allFieldSets } from '@/api/field_labels';
import {
  getEmptyLocaleValue,
  type AlzFormData,
  type FieldLabel,
} from '@/api/types';
import { alzFormTopics, useDynamicText } from '@/api/masterdata';
import { useRouter } from 'vue-router';
import { computed, ref, watch } from 'vue';
import { throwUnauthorized } from '@/utilities/throwErrorPageTrigger';
import { translateStringOrLocale } from '@/i18n';
import { useCurrentUser } from '@/api/auth';
import { submitContactForm } from '@/api/contactForm';
import { useValidationTracking } from '@/utilities/useValidationTracking';

const { t } = useI18n();
const router = useRouter();

const user = useCurrentUser();

watch(
  user,
  () => {
    if (!user.value) {
      throwUnauthorized();
    }
  },
  { immediate: true },
);

const contactFieldKeys = allFieldSets.kssContactFieldKeys.fields;

const { loaded: textLoaded, result: texts } = useDynamicText(
  allFieldSets.alzFieldKeys.group,
  undefined,
  undefined,
  router,
);
const dbFieldLookup = computed(() => {
  const lookup: { [key: string]: FieldLabel } = {};
  for (const fld of texts.value?.dbFieldLabels ?? []) {
    lookup[`${fld.group}.${fld.field}`] = fld;
  }
  return lookup;
});
function getDynamicLabelProps(
  key: keyof typeof contactFieldKeys,
): Pick<IInputLabelProps, 'dynamicTextKey' | 'mdDescription' | 'link'> {
  return {
    dynamicTextKey: contactFieldKeys[key],
    mdDescription:
      dbFieldLookup.value[contactFieldKeys[key]]?.richTextDescription,
    link: dbFieldLookup.value[contactFieldKeys[key]]?.link,
  };
}

const topicOptions = toDropdownItems(
  alzFormTopics.result.value,
  (topic) => [topic.key, translateStringOrLocale(topic.label).value, topic],
  true,
);

const data = ref<AlzFormData>({
  topic: { key: '', label: getEmptyLocaleValue(), recipient: '' },
  senderEmail: user.value?.email ?? '',
  senderName: user.value
    ? (user.value.fullName ?? `${user.value.firstName} ${user.value.lastName}`)
    : '',
  subject: '',
  request: '',
});

const showValidationMessages = ref<boolean>(false);
const { formRef, isValid } = useValidationTracking(
  data,
  showValidationMessages,
  true,
  true,
);

const submitting = ref(false);
const submitted = ref(false);
const submitFailed = ref(false);
function submit() {
  submitting.value = true;
  submitted.value = false;
  submitFailed.value = false;
  try {
    submitContactForm({
      recipients: data.value.topic.recipient
        .split(';')
        .map((email) => ({ email })),
      subject: data.value.subject,
      sender: {
        email: data.value.senderEmail,
        name: data.value.senderName ? data.value.senderName : undefined,
      },
      mainBody: data.value.request,
    });
    submitted.value = true;
  } catch (err) {
    // TODO: better error handling
    console.error(err);
    submitFailed.value = true;
  }
  submitting.value = false;
}
</script>

<template>
  <ContentPage
    :title="t('header.items.contact.kss')"
    class="bg-gnist-gray-light-light"
  >
    <ParagraphShimmer
      v-if="!textLoaded"
      :lines="6"
      :random-size="true"
      class="mx-auto w-full max-w-5xl"
    />
    <form v-else ref="formRef" class="mx-2 md:mx-auto">
      <Dropdown
        v-model="data.topic"
        v-bind="getDynamicLabelProps('topic')"
        :options="topicOptions"
        :getkey="(t) => t?.key"
        required
      />
      <TextInput
        v-model="data.senderEmail"
        v-bind="getDynamicLabelProps('senderEmail')"
        required
      />
      <TextInput
        v-model="data.senderName"
        v-bind="getDynamicLabelProps('senderName')"
      />
      <TextInput
        v-model="data.subject"
        v-bind="getDynamicLabelProps('title')"
        required
      />
      <TextInput
        v-model="data.request"
        v-bind="getDynamicLabelProps('request')"
        multiline
        :rows="10"
        required
      />
      <div class="flex items-center justify-end gap-4">
        <MaterialIcon
          v-if="submitted"
          icon="check_circle"
          label="orders.alz.buttons.values.submitted"
          class="material-symbols-rounded ml-1 cursor-default text-3xl font-extrabold text-gnist-green"
        />
        <MaterialIcon
          v-if="submitFailed"
          icon="error_outline"
          label="orders.alz.buttons.values.submitted"
          class="material-symbols-rounded ml-1 cursor-default text-3xl font-extrabold text-gnist-red"
        />
        <LoadingSpinner v-if="submitting" class="h-9 w-9" />
        <ButtonComponent
          text="contact.kss.buttons.values.send"
          class="my-2 w-auto self-end justify-self-start"
          :disabled="submitting || submitted || !isValid"
          @click.prevent="submit"
        />
      </div>
    </form>
  </ContentPage>
</template>
