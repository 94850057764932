<script setup lang="ts">
import ContentPage from '@/components/ContentPage.vue';
import ButtonComponent from '@/components/ButtonComponent.vue';
import ModalComponent from '@/components/ModalComponent.vue';
import TextInput from '@/components/forms/InputText.vue';
import MarkdownInput from '@/components/forms/InputMarkdown.vue';
import Picker from '@/components/forms/InputPicker.vue';
import Dropdown, {
  toDropdownItems,
} from '@/components/forms/InputDropdown.vue';
import Checkbox from '@/components/forms/InputCheckbox.vue';
import { useI18n } from 'vue-i18n';
import {
  type KeyOfRecursive,
  type ApplicationService,
  type AgreementReference,
  type Company,
  type SimpleLookupValue,
  type PartialSome,
  type User,
  getEmptyLocaleValue,
  type FieldLabel,
  type CustomText,
  type FormConfig,
  formConfigOwners,
  type FormConfigDTO,
  type ArchType,
  type InformationClass,
  type AlzFormTopic,
} from '@/api/types';
import { computed, ref, watch, watchEffect } from 'vue';
import TableComponent from '@/components/TableComponent.vue';
import EditSimpleLookupValue from '@/components/EditSimpleLookupValue.vue';
import {
  addMasterDataRow,
  deleteMasterDataRow,
  getMasterData,
  updateMasterDataRow,
  type MdEndpoint,
} from '@/api/masterdata';
import { assertDefined } from '@/utilities/debugUtils';
import { mapValueList } from '@/utilities/mapValueList';
import { useRoles, useUsers } from '@/api/user';
import { sleepUntil } from '@/utilities/sleep';
import EditFieldLabel from '@/components/EditFieldLabel.vue';
import {
  richTextPrefix,
  type FieldSetKey,
  type TextSetKey,
} from '@/api/field_labels';
import EditCustomText from '@/components/EditCustomText.vue';
import { useStoreAbility } from '@/abilities';

const { t } = useI18n();
const { can } = useStoreAbility();
const { result: roles } = useRoles();
const roleOptions = computed(() =>
  mapValueList(
    roles.value?.map((r) => r.name) ?? [],
    'userAdmin.manualRoles',
    true,
  ),
);

type TableMap = {
  [TKey in MdEndpoint]: {
    endpoint: TKey;
  } & (TKey extends 'application_services'
    ? ApplicationService
    : TKey extends 'agreement_references'
      ? AgreementReference
      : TKey extends 'companies'
        ? Company
        : TKey extends 'infoclasses'
          ? InformationClass
          : TKey extends 'criticalities'
            ? SimpleLookupValue<number>
            : TKey extends 'archtypes'
              ? ArchType
              : TKey extends 'default_alz_roles'
                ? SimpleLookupValue
                : TKey extends 'default_github_teams'
                  ? SimpleLookupValue
                  : TKey extends 'field_labels'
                    ? FieldLabel
                    : TKey extends 'strings'
                      ? CustomText
                      : TKey extends 'form_configurations'
                        ? FormConfig
                        : TKey extends 'alz_form_topics'
                          ? AlzFormTopic
                          : never);
};

const selectedType = ref<MdEndpoint>('application_services');
const _selectedItem = ref<TableMap[MdEndpoint] | null>(null);
const selectedItem = computed({
  get() {
    return _selectedItem.value;
  },
  set(value) {
    if (_selectedItem.value && tableData.value) {
      const match = tableData.value.indexOf(_selectedItem.value);
      if (match > -1 && value) {
        tableData.value[match] = value;
      }
    }
    _selectedItem.value = value;
  },
});

const _selectedUser = ref<User>();
const selectedUser = computed({
  get: () => _selectedUser.value,
  set: (value) => {
    if (selectedItem.value?.endpoint !== 'agreement_references') {
      _selectedUser.value = undefined;
    } else {
      _selectedUser.value = value;
      selectedItem.value.forUserId = value?.id;
      selectedItem.value.forUserFullname = value?.fullName;
    }
  },
});
watch(selectedItem, async () => {
  if (selectedItem.value?.endpoint !== 'agreement_references') {
    selectedUser.value = undefined;
  } else if (
    !!selectedItem.value?.forUserId &&
    selectedUser.value?.id !== selectedItem.value?.forUserId
  ) {
    selectedUser.value = await getUserById(selectedItem.value?.forUserId);
  }
});

const users = useUsers();
async function searchUsers(query: string): Promise<User[]> {
  if (!users.value) return [];
  const itemLimit = 10;
  const matches =
    query.trim() == ''
      ? users.value
      : users.value.filter((user) =>
          user.fullName?.toLowerCase().includes(query),
        );
  return matches.slice(0, itemLimit);
}
async function getUserById(userId: string | number) {
  await sleepUntil(() => !!users.value, 1000, 10);
  const match = users.value?.find((a) => a.id == userId);
  return match;
}

const tableData = ref<TableMap[MdEndpoint][]>();
const loadingData = ref(false);
const loadFailed = ref(false);
watchEffect(async () => {
  loadingData.value = true;
  loadFailed.value = false;
  tableData.value = undefined;
  try {
    tableData.value = await getMasterData<TableMap[MdEndpoint]>(
      selectedType.value,
    );
    tableData.value.map((item) => (item.endpoint = selectedType.value));
    if (selectedType.value === 'form_configurations') {
      (tableData.value as FormConfig[]).map((item) => {
        item.config = JSON.parse((item as FormConfigDTO).jsonValue);
      });
    }
  } catch (err) {
    console.error(err);
    loadFailed.value = true;
  }
  loadingData.value = false;
});

function getKeyProperty<
  TKey extends MdEndpoint,
  TReturn = keyof PartialSome<TableMap[MdEndpoint], 'endpoint'>,
>(type: TKey): TReturn {
  if (type === 'application_services') {
    return 'applicationUUID' satisfies keyof ApplicationService as TReturn;
  } else if (type === 'agreement_references') {
    return 'agreementNumber' satisfies keyof AgreementReference as TReturn;
  } else if (type === 'companies') {
    return 'shortName' satisfies keyof Company as TReturn;
  } else if (type === 'infoclasses') {
    return 'key' satisfies keyof SimpleLookupValue as TReturn;
  } else if (type === 'criticalities') {
    return 'key' satisfies keyof SimpleLookupValue as TReturn;
  } else if (type === 'archtypes') {
    return 'key' satisfies keyof SimpleLookupValue as TReturn;
  } else if (type === 'field_labels') {
    return 'field' satisfies keyof FieldLabel as TReturn;
  } else if (type === 'strings') {
    return 'identifier' satisfies keyof CustomText as TReturn;
  } else if (type === 'form_configurations') {
    return 'owner' satisfies keyof FormConfigDTO as TReturn;
  } else if (type === 'default_alz_roles') {
    return 'key' satisfies keyof SimpleLookupValue as TReturn;
  } else if (type === 'default_github_teams') {
    return 'key' satisfies keyof SimpleLookupValue as TReturn;
  } else if (type === 'alz_form_topics') {
    return 'key' satisfies keyof AlzFormTopic as TReturn;
  } else {
    throw new Error('Unknown type of masterdata, cannot get key property');
  }
}

function getKey<
  TKey extends MdEndpoint,
  TItem extends PartialSome<TableMap[MdEndpoint], 'endpoint'>,
>(type: TKey | undefined, item: TItem): string | number | null {
  if (type === undefined) return null;
  const key = item[getKeyProperty(type)];
  if (typeof key === 'string' || typeof key === 'number') return key;
  else return null;
}

async function onSave(item: typeof selectedItem.value) {
  if (!tableData.value) return;
  if (!assertDefined(item)) return;
  const endpoint = item.endpoint;
  const itemToSave: PartialSome<TableMap[MdEndpoint], 'endpoint'> = {
    ...item,
    endpoint: undefined,
  };
  if (endpoint === 'form_configurations') {
    (itemToSave as FormConfigDTO).jsonValue = JSON.stringify(
      (itemToSave as FormConfig).config,
    );
    delete (itemToSave as FormConfig).config;
  }
  const keyProp = getKeyProperty(endpoint);
  let key: string | undefined = itemToSave[keyProp];
  if (!key) {
    const result = await addMasterDataRow(endpoint, itemToSave, keyProp);
    (item as typeof itemToSave)[keyProp] = result[keyProp];
  } else {
    if (item.endpoint === 'field_labels' || item.endpoint === 'strings') {
      key = `${item.group}/${key}`;
    }
    await updateMasterDataRow(endpoint, key, itemToSave);
  }
}

const columnsMap: {
  [TKey in MdEndpoint]: KeyOfRecursive<TableMap[TKey]>[];
} = {
  application_services: ['applicationUUID', 'name'],
  agreement_references: ['agreementNumber', 'costCenter', 'forUserId', 'label'],
  companies: ['shortName', 'name'],
  infoclasses: ['key', 'text.no', 'extendedDescription.no'],
  criticalities: ['key', 'text.no', 'extendedDescription.no'],
  archtypes: ['key', 'text.no', 'extendedDescription.no'],
  field_labels: ['group', 'field', 'label.no'],
  strings: ['group', 'identifier', 'text.no'],
  form_configurations: ['owner'],
  default_alz_roles: ['key', 'text.no'],
  default_github_teams: ['key', 'text.no'],
  alz_form_topics: ['label.no'],
};

const columns = computed((): KeyOfRecursive<TableMap[MdEndpoint]>[] => {
  return columnsMap[selectedType.value];
});

function getColumnHeader(column: KeyOfRecursive<TableMap[MdEndpoint]>) {
  return t(`masterData.${selectedType.value}.${column}`);
}

function getNewItem(): PartialSome<TableMap[MdEndpoint], 'endpoint'> | null {
  if (selectedType.value === 'application_services') {
    const newItem: ApplicationService = {
      applicationUUID: '',
      name: '',
    };
    return newItem;
  } else if (selectedType.value === 'agreement_references') {
    const newItem: AgreementReference = {
      agreementNumber: '',
      forUserId: '',
    };
    return newItem;
  } else if (selectedType.value === 'companies') {
    const newItem: Company = {
      shortName: '',
      name: '',
    };
    return newItem;
  } else if (selectedType.value === 'infoclasses') {
    const newItem: SimpleLookupValue = {
      key: '',
      text: getEmptyLocaleValue(),
      extendedDescription: getEmptyLocaleValue(),
    };
    return newItem;
  } else if (selectedType.value === 'criticalities') {
    const newItem: SimpleLookupValue<number> = {
      key: -1,
      text: getEmptyLocaleValue(),
      extendedDescription: getEmptyLocaleValue(),
    };
    return newItem;
  } else if (selectedType.value === 'archtypes') {
    const newItem: SimpleLookupValue = {
      key: '',
      text: getEmptyLocaleValue(),
      extendedDescription: getEmptyLocaleValue(),
    };
    return newItem;
  } else if (selectedType.value === 'field_labels') {
    const newItem: FieldLabel = {
      group: '' as FieldSetKey,
      field: '',
      label: getEmptyLocaleValue(),
      tooltip: getEmptyLocaleValue(),
      placeholder: getEmptyLocaleValue(),
      description: getEmptyLocaleValue(),
    };
    return newItem;
  } else if (selectedType.value === 'strings') {
    const newItem: CustomText = {
      group: '' as TextSetKey,
      identifier: '',
      text: getEmptyLocaleValue(),
    };
    return newItem;
  } else if (selectedType.value === 'form_configurations') {
    const newItem: FormConfig & FormConfigDTO = {
      owner: '',
      jsonValue: '',
      config: {},
    };
    return newItem;
  } else if (selectedType.value === 'default_alz_roles') {
    const newItem: SimpleLookupValue = {
      key: '',
      text: getEmptyLocaleValue(),
      extendedDescription: getEmptyLocaleValue(),
    };
    return newItem;
  } else if (selectedType.value === 'default_github_teams') {
    const newItem: SimpleLookupValue = {
      key: '',
      text: getEmptyLocaleValue(),
      extendedDescription: getEmptyLocaleValue(),
    };
    return newItem;
  } else if (selectedType.value === 'alz_form_topics') {
    const newItem: AlzFormTopic = {
      key: crypto.randomUUID(),
      label: getEmptyLocaleValue(),
      recipient: '',
    };
    return newItem;
  } else {
    return null;
  }
}

function onCreateButtonClick() {
  if (!tableData.value) return null;
  const item = getNewItem();
  if (!item) return null;
  tableData.value.push(item as TableMap[MdEndpoint]);
  item.endpoint = selectedType.value;
  return item;
}
async function onDelete(itemToDelete: TableMap[MdEndpoint]) {
  let key = getKey(selectedType.value, itemToDelete);
  if (!key) return;
  if (
    itemToDelete.endpoint === 'field_labels' ||
    itemToDelete.endpoint === 'strings'
  ) {
    key = `${itemToDelete.group}/${key}`;
  }
  await deleteMasterDataRow(selectedType.value, key);
}

const typeOptions = mapValueList(
  (Object.keys(columnsMap) as MdEndpoint[]).filter((col: MdEndpoint) => {
    if (col === 'agreement_references') {
      return can('manage', 'AgreementReference');
    } else {
      return can('manage', 'MasterData');
    }
  }),
  'masterData.selectedType',
);
const formConfigOwnerOptions = toDropdownItems(
  formConfigOwners,
  (item) => [item, item],
  true,
);

const jsonValue = ref<string>();
const replaceExisting = ref(false);
function importJson() {
  if (!assertDefined(jsonValue.value)) return;
  let toImport: typeof tableData.value;
  try {
    toImport = JSON.parse(jsonValue.value);
    if (!toImport) return;
  } catch (err) {
    console.error(err);
    return;
  }
  for (const row of toImport) {
    const keyProp = getKeyProperty(selectedType.value);
    const key = row[keyProp];
    if (tableData.value?.find((r) => r[keyProp] === key)) {
      if (replaceExisting.value) {
        tableData.value = tableData.value?.filter((r) => r[keyProp] !== key);
      } else {
        continue;
      }
    }
    row.endpoint = selectedType.value;
    if (selectedType.value === 'form_configurations') {
      (row as FormConfig).config = JSON.parse((row as FormConfigDTO).jsonValue);
      delete (row as Partial<FormConfigDTO>).jsonValue;
    }
    tableData.value?.push(row);
    onSave(row); // Just trigger async function, let each run by itself
  }
  jsonValue.value = undefined;
}
</script>

<template>
  <ContentPage
    :title="t('header.items.masterdataEdit')"
    class="bg-gnist-gray-light-light"
  >
    <template #list>
      <div class="absolute z-10 hidden w-full md:block">
        <div
          class="gnist-page-width grid h-8 w-full grid-flow-col justify-end gap-4 px-4"
        >
          <ButtonComponent
            text="admin.blockProduction.importExport.header"
            icon="import_export"
            icon-class="text-xl"
            chrome
            type="special"
            class="tooltip-bottom h-6 self-center"
            @click.prevent="jsonValue = JSON.stringify(tableData)"
          />
          <ModalComponent
            size="big"
            :show-modal="jsonValue !== undefined"
            title="admin.blockProduction.importExport.header"
            primary-button-type="danger"
            button-text="admin.blockProduction.importExport.import"
            :title-upper-case="false"
            @close="jsonValue = undefined"
            @handle-click="importJson"
          >
            <TextInput
              v-model="jsonValue"
              label="admin.blockProduction.importExport.JSON"
              required
              multiline
              :rows="20"
            />
          </ModalComponent>
        </div>
      </div>
    </template>
    <section class="py-8">
      <div class="w-full max-w-full">
        <div v-if="loadFailed" class="alert alert-error">
          {{ t('masterData.fail') }}
        </div>
        <div v-if="typeOptions.length === 0" class="alert alert-warning">
          {{ t('masterData.notAvailable') }}
        </div>
        <TableComponent
          v-else
          size="table-xs"
          :rows="tableData"
          show-spinner
          i18n-key="tagEditor.usageColumns"
          :get-key="(item) => getKey(selectedType, item) ?? 'uknown_key'"
          :columns="columns"
          :get-column-header="getColumnHeader"
          :sort-fields="columns"
          :default-sortfield="columns[1]"
          :create="{
            idProperty: getKeyProperty(selectedType),
            onCreateClick: onCreateButtonClick,
          }"
          :edit="{
            onSave: async () => await onSave(selectedItem),
            isSelectedItemValid: true,
            title:
              selectedType === 'infoclasses' ||
              selectedType === 'criticalities' ||
              selectedType === 'archtypes'
                ? t(`masterData.selectedType.values.${selectedType}`)
                : undefined,
            modalProps: { size: 'big' },
          }"
          :delete="{
            onDelete,
            getIdentificator: (row) =>
              getKey(selectedType, row)?.toString() ?? '?',
          }"
          initial-row-class="my-6 flex justify-between"
          @remove:row="
            (item) => {
              if (!item) return;
              const key = getKey(selectedType, item);
              tableData =
                tableData?.filter((i) => getKey(selectedType, i) !== key) ?? [];
            }
          "
          @update:selected-item="
            (item) => (selectedItem = item as TableMap[MdEndpoint])
          "
        >
          <template #beforeCreate>
            <Dropdown
              v-model="selectedType"
              label="masterData.selectedType.title"
              :options="typeOptions"
              :getkey="(t) => t"
              :disabled="loadingData"
              class="[&>label]:flex-row [&>label]:gap-2 [&_.GnistInputLabel]:min-w-fit"
            />
          </template>
          <template #columns="{ item: row }">
            <template v-if="row.endpoint === 'application_services'">
              <td>
                {{ row.applicationUUID }}
              </td>
              <td>
                {{ row.name }}
              </td>
            </template>
            <template v-else-if="row.endpoint === 'agreement_references'">
              <td>
                {{ row.agreementNumber }}
              </td>
              <td>
                {{ row.costCenter }}
              </td>
              <td>
                {{ row.forUserFullname }}
              </td>
              <td>
                {{ row.label }}
              </td>
            </template>
            <template v-else-if="row.endpoint === 'companies'">
              <td>
                {{ row.shortName }}
              </td>
              <td>
                {{ row.name }}
              </td>
            </template>
            <template
              v-else-if="
                row.endpoint === 'infoclasses' ||
                row.endpoint === 'criticalities' ||
                row.endpoint === 'archtypes' ||
                row.endpoint === 'default_alz_roles' ||
                row.endpoint === 'default_github_teams'
              "
            >
              <td>
                {{ row.key }}
              </td>
              <td>
                {{ row.text.no }}
              </td>
              <td>
                {{ row.extendedDescription.no }}
              </td>
            </template>
            <template v-else-if="row.endpoint === 'field_labels'">
              <td>
                {{
                  t(
                    `masterData.field_labels.groups.${row.group?.replaceAll('.', '_')}`,
                  )
                }}
              </td>
              <td>
                {{ t(`${row.group}.${row.field}.label`) }}
              </td>
              <td>
                {{ row.label.no }}
              </td>
            </template>
            <template v-else-if="row.endpoint === 'strings'">
              <td>
                {{
                  t(
                    `masterData.strings.groups.${row.group?.replaceAll('.', '_')}`,
                  )
                }}
              </td>
              <td>
                {{
                  t(
                    `${row.group.replace(richTextPrefix, '')}.${row.identifier}`,
                  )
                }}
              </td>
              <td>
                {{ row.text.no.substring(0, 50) }}
                {{ row.text.no.length > 50 ? '...' : '' }}
              </td>
            </template>
            <template v-else-if="row.endpoint === 'form_configurations'">
              <td>{{ row.owner }}</td>
            </template>
            <template v-else-if="row.endpoint === 'alz_form_topics'">
              <td>{{ row.label.no }}</td>
            </template>
            <template v-else>
              <td>Ukjent</td>
            </template>
          </template>
          <template #editor>
            <template v-if="selectedItem?.endpoint === 'application_services'">
              <TextInput
                v-model="selectedItem.applicationUUID"
                label="masterData.application_services.applicationUUID"
                required
              />
              <TextInput
                v-model="selectedItem.name"
                label="masterData.application_services.name"
                required
              />
            </template>
            <template
              v-else-if="selectedItem?.endpoint === 'agreement_references'"
            >
              <TextInput
                v-model="selectedItem.agreementNumber"
                label="masterData.agreement_references.agreementNumber"
                required
              />
              <TextInput
                v-model="selectedItem.costCenter"
                label="masterData.agreement_references.costCenter"
                required
              />
              <Picker
                v-model="selectedUser"
                label="masterData.agreement_references.forUserId"
                :getkey="(val) => val?.id ?? ''"
                :gettext="(val) => val?.fullName ?? ''"
                :taglistclass="[
                  'w-full grow [&>*]:w-full [&>*]:justify-between',
                ]"
                :filter="searchUsers"
                :get-by-id="getUserById"
                :max-items="1"
                required
              />
              <TextInput
                v-model="selectedItem.label"
                label="masterData.agreement_references.label"
              />
            </template>
            <template v-else-if="selectedItem?.endpoint === 'companies'">
              <TextInput
                v-model="selectedItem.shortName"
                label="masterData.companies.shortName"
                required
              />
              <TextInput
                v-model="selectedItem.name"
                label="masterData.companies.name"
                required
              />
            </template>
            <template v-else-if="selectedItem?.endpoint === 'infoclasses'">
              <EditSimpleLookupValue v-model="selectedItem">
                <Checkbox
                  v-model="selectedItem.sandbox"
                  label="masterData.infoclasses.sandbox"
                  direction="horizontal"
                  mode="toggle"
                />
              </EditSimpleLookupValue>
            </template>
            <template v-else-if="selectedItem?.endpoint === 'criticalities'">
              <EditSimpleLookupValue v-model="selectedItem" />
            </template>
            <template v-else-if="selectedItem?.endpoint === 'archtypes'">
              <EditSimpleLookupValue v-model="selectedItem">
                <Dropdown
                  v-model="selectedItem.roles"
                  label="masterData.archtypes.roles"
                  :options="roleOptions"
                  :getkey="(t) => t ?? ''"
                  allow-empty-value
                  disable-empty-option
                  :multiple="{ emptyLabel: '' }"
                />
                <TextInput
                  v-model="selectedItem.targetRepo"
                  label="masterData.archtypes.repo"
                  placeholder="masterData.archtypes.repoPlaceholder"
                />
              </EditSimpleLookupValue>
            </template>
            <template v-else-if="selectedItem?.endpoint === 'field_labels'">
              <EditFieldLabel
                v-model="selectedItem"
                :all-fields="tableData as FieldLabel[]"
              />
            </template>
            <template v-else-if="selectedItem?.endpoint === 'strings'">
              <EditCustomText
                v-model="selectedItem"
                :all-fields="tableData as CustomText[]"
              />
            </template>
            <template
              v-else-if="selectedItem?.endpoint === 'form_configurations'"
            >
              <Dropdown
                v-model="selectedItem.owner"
                label="masterData.form_configurations.owner"
                :options="formConfigOwnerOptions"
                :getkey="(t) => t ?? ''"
                allow-empty-value
                disable-empty-option
                :disabled="!!selectedItem.owner"
              />
              <template v-if="selectedItem.owner === ''">
                <!-- AWAITING FORM TYPE SELECTION-->
              </template>
              <template v-else-if="selectedItem.owner === 'AzureLandingZones'">
                <TextInput
                  v-model="selectedItem.config.location"
                  label="location"
                  required
                />
                <TextInput
                  v-model="selectedItem.config.sandboxApplicationUUID"
                  label="default_address_space"
                  required
                />
                <TextInput
                  v-model="selectedItem.config.workload"
                  label="workload"
                  required
                />
                <TextInput
                  v-model="selectedItem.config.sandboxApplicationUUID"
                  label="Sandbox ApplicationUUID"
                  required
                />
                <MarkdownInput
                  v-model="selectedItem.config.pr_template"
                  for-name="pr_template"
                  label="pr_template"
                  :description="'### Placeholders\n* `{{FormUrl}}`: Url to edit this PR and the order form\n* `{{UserInfo}}`: Information about the current user\n * `{{LastUpdated}}`: Current date and time (when the template is applied)'"
                  md-description
                  editor-height="20rem"
                  class="col-span-2"
                />
              </template>
              <template v-else>
                {{ t('masterData.form_configurations.unknownForm') }}
              </template>
            </template>
            <template
              v-else-if="selectedItem?.endpoint === 'default_alz_roles'"
            >
              <EditSimpleLookupValue v-model="selectedItem" />
            </template>
            <template
              v-else-if="selectedItem?.endpoint === 'default_github_teams'"
            >
              <EditSimpleLookupValue v-model="selectedItem" />
            </template>
            <template v-else-if="selectedItem?.endpoint === 'alz_form_topics'">
              <TextInput
                v-model="selectedItem.label.no"
                :label="`${t('language.values.no')} ${t('masterData.alz_form_topics.label.no')}`"
                required
              />
              <TextInput
                v-model="selectedItem.label.en"
                :label="`${t('language.values.en')} ${t('masterData.alz_form_topics.label.no')}`"
                required
              />
              <TextInput
                v-model="selectedItem.recipient"
                label="masterData.alz_form_topics.recipient"
                required
              />
            </template>
            <template v-else>
              {{ t('masterData.unknownDetailsType') }}
              <pre>
              {{ JSON.stringify(selectedItem) }}
              </pre>
            </template>
          </template>
        </TableComponent>
      </div>
    </section>
  </ContentPage>
</template>
