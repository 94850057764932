<script lang="ts" setup>
import { ref, watchEffect } from 'vue';
import { useCategoriesLazy } from '@/api/category';
import { useTagGroups } from '@/api/tags';
import TagList from './TagList.vue';
import type { Tag, TagGroup } from '@/api/types';
import { translateStringOrLocale } from '@/i18n';

const props = defineProps<{
  categoryName: string;
  tags?: Tag[];
}>();

const { result: categories } = useCategoriesLazy(false);
const { result: tagGroups } = useTagGroups();
const filtered_groups = ref<TagGroup[]>();

watchEffect(() => {
  const category = categories.value?.find(
    (c) => translateStringOrLocale(c.name).value == props.categoryName,
  );
  const groups = tagGroups.value?.filter((g) =>
    category?.tagGroups?.includes(g.id.toString()),
  );

  if (!groups) return;
  filtered_groups.value = groups.filter((g) =>
    g.tags?.some((gt) => props.tags?.some((pt) => pt.id === gt)),
  );

  filtered_groups.value.forEach((group) => {
    group.tags = group.tags?.filter((t) =>
      props.tags?.some((pt) => pt.id === t),
    );
  });
});
</script>

<template>
  <div>
    <div v-for="tagGroup in filtered_groups" :key="tagGroup.id">
      <TagList
        v-if="tagGroup"
        :group="tagGroup.id"
        :list="tagGroup.tags"
        :size="'badge-sm'"
        :show-all="true"
        :show-group-label="true"
      />
    </div>
  </div>
</template>
